import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Index.vue'),
    meta:{
      title:'EUTIME explores the infinite possibilities of the world with you.',
      description:"EUTIME, we navigate through the complex web world for you, sorting and filtering to ensure you get diverse news updates in a timely manner. EUTIME explores the infinite possibilities of the world with you.",
      keyword:"EUTIME,Complex web world,Sorting,Filtering,Diverse news updates,Timely manner,Explore,Infinite possibilities"
    },
  },
  {
    path: '/:path',
    name: 'list',
    component: () => import('../views/List.vue'),
    meta:{
      title:'Topic List',
      description:" ",
      keyword:" "
    },
  },
  {
    path: '/detail/:date/:id',
    name: 'detail',
    component: () => import('../views/Detail.vue'),
    meta:{
      title:'content page',
      description:"",
      keyword:" "
    },
    
  },
    // 404页面重定向到首页
    {
      path: '/:catchAll(.*)', // 使用正则表达式匹配所有路径
      name: 'NotFound',
      redirect: '/' // 当匹配到不存在的路径时，重定向到首页
    }
]

const router = new VueRouter({
  mode:'history',
  routes,
  scrollBehavior (to, from, savedPosition) {  //设置跳转到新页面顶部
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// 针对ElementUI导航栏中重复导航报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
