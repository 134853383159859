export function resize(){
    var html = document.documentElement,
        w = html.clientWidth,
        dpr = parseInt(window.devicePixelRatio || 1);
    if(document.documentElement.clientWidth <= 900){
        html.setAttribute('data-dpr', dpr);
        html.style.fontSize = w/640*100 + 'px';
    }else{
        html.removeAttribute('data-dpr');
        html.removeAttribute('style');
        html.style.fontSize = '50px';
    }
};

