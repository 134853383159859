import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import axios from 'axios'
import '@/assets/css/reset.css'
import '@/assets/css/style.scss'
import { resize } from "@/assets/js/rem";
import dayjs from 'dayjs';
Vue.prototype.dateFilter = dayjs;
Vue.prototype.resize = resize;
Vue.prototype.resize();
window.onresize = function() {
  Vue.prototype.resize();
};
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$http=axios;
// Google Analytics 跟踪代码
Vue.prototype.$gtag = function () {
  if (window.gtag) {
    window.gtag(...arguments);
  }
};

router.beforeEach((to, from , next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription && to.meta.description) {
    metaDescription.setAttribute('content', to.meta.description);
  }
   // 查找并更新keyword meta标签
   const metaKeyword = document.querySelector('meta[name="keywords"]');
   if (metaKeyword && to.meta.keyword) {
     metaKeyword.setAttribute('content', to.meta.keyword);
   }
 
  if (window.gtag) {
    window.gtag('config', 'G-Q487C3VCBN', {
      'page_path': to.fullPath
    });
  }
  next();
});

new Vue({
  created() {
    // 初始化 Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', 'G-Q487C3VCBN');
  },
  router,
  render: h => h(App)
}).$mount('#app')
